import axios from 'axios';
import BASE_URL from "../services/api-url";

const API_URL = BASE_URL+"user/";

const login = async (email, password) => {
    try {
        const response = await axios.post(API_URL + "login", { email, password });
        if (response.data.data.accesstoken) {
            localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
    } catch (error) {
        console.log("Failed to Login",error) 
    }
};

const refreshAccessToken = async (token,refreshToken) => {
    try {
        const response = await axios.post(
            API_URL + "refreshtoken",
            { refreshToken: refreshToken },
            { headers: {'Authorization': `Bearer ${token}`,},}
        );
        if (response.data.accesstoken) {
            const currentUser = JSON.parse(localStorage.getItem("user")) || {};
            const updatedUser = { 
                ...currentUser, 
                data: { 
                    ...currentUser.data, 
                    accesstoken: response.data.accesstoken 
                }
            };
            localStorage.setItem("user", JSON.stringify(updatedUser));
        }
        return response.data.accesstoken;
    } catch (error) {
        console.error("Failed to refresh access token", error);
        return null;
    }
};


const registeruser = async (userDetails) => {
    try {
        const response = await axios.post(API_URL+'register', userDetails, {
        });
        return response.data;
    } catch (error) {
        if (error.response) {
            return error.response;
        } else if (error.request) {
            return "Network error. Please try again.";
        } else {
            return "An unexpected error occurred.";
        }
    }
};

const logout = () => {
    localStorage.removeItem("user");
};

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
    login,
    logout,
    getCurrentUser,
    refreshAccessToken,
    registeruser
};

export default AuthService;