import React, { lazy, Suspense,useContext } from 'react';
import { Routes, Route,useLocation } from 'react-router-dom';
import TopNavbar from '../components/common/topNavbar';
import TopNavbar1 from '../components/common/topNavbarPublic';
import Header from '../components/common/header';
import { Oval } from 'react-loader-spinner';
import { UserContext } from "../context/theme";
import { ToastContainer } from 'react-toastify'; 
import "./layout.scss";

const Dashboard = lazy(() => import('../pages/dasbboard/dashboard'));
const Folder = lazy(() => import('../pages/folder/folder'));
const Files = lazy(() => import('../pages/files/files'));
const SharedFolder = lazy(() => import('../pages/folder/sharedFolder'));
const MainPublic =lazy(()=>import('../pages/folder/publicFolder'));
const MainPublicNested =lazy(()=>import('../pages/folder/publicFolderNested'));

const Layout = () => {
    const context = useContext(UserContext); 
    const location = useLocation(); 
    const isPublicRoute = location.pathname.startsWith("/public/");
    let token = localStorage.getItem("publicLinkToken")
    return (
      <>
        {token ? (
          // ✅ Apply only for Public routes
          <div className={`page-wrapper ${context.Theme} ${context.ThemeColor} ${context.MiniSidebar ? "mini-sidebar" : ""}`}>

          <div>         <TopNavbar1 />
          <div className="all-content-wrapper all-content-wrapper_smallscreen" style={{ marginLeft: "unset" }}>
               
            <Suspense
              fallback={
                <div className="loading-container">
                  <div className="loading-spinner">
                    <Oval
                      visible={true}
                      height="80"
                      width="80"
                      color="#6f42c1"
                      primaryColor="#6f42c1"
                      secondaryColor="#6f42c1"
                      backgroundColor="#6f42c1"
                      ariaLabel="oval-loading"
                    />
                  </div>
                </div>
              }
            >
              <Routes>
                <Route path="/Public/:token" element={<MainPublic />} />
                <Route path="/publicfolder/:parentId/:foldername/:prevParentId" element={<MainPublicNested />} />
              </Routes>
            </Suspense>
          </div>
          </div>
          </div>
        ) : (
          // ✅ Apply for all other routes
          <div
            className={`page-wrapper 
              ${context.Theme} 
              ${context.ThemeColor} 
              ${context.MiniSidebar ? "mini-sidebar" : ""}      
            `}
          >
            <Header />
            <ToastContainer />
            <TopNavbar />
    
            <div
              className={context.isExpanded ? 
                "all-content-wrapper all-content-wrapper_smallscreen conditionallcontent" 
                : "all-content-wrapper"
              }
            >
              <div className="mainContent mt-4">
                <div className="container-fluid"></div>
                <Suspense
                  fallback={
                    <div className="loading-container">
                      <div className="loading-spinner">
                        <Oval
                          visible={true}
                          height="80"
                          width="80"
                          color="#6f42c1"
                          primaryColor="#6f42c1"
                          secondaryColor="#6f42c1"
                          backgroundColor="#6f42c1"
                          ariaLabel="oval-loading"
                        />
                      </div>
                    </div>
                  }
                >
                  <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/folder/:parentId/:foldername/:prevParentId" element={<Folder />} />
                    <Route path="/publicfolder/:parentId/:foldername/:prevParentId" element={<MainPublicNested />} />
                    <Route path="/sharedfolder/:parentId/:foldername/:prevParentId" element={<SharedFolder />} />
                  </Routes>
                </Suspense>
              </div>
            </div>
          </div>
        )}
      </>
    );    
  };
  
  export default Layout;