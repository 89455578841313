import React, { useContext, useState,useEffect } from "react";
import { NavLink } from "react-router-dom";
import DropDownMenu, { Dropdown, Menu } from "./sidebarMenu";
import { UserContext } from "../../context/theme";
import { Icon } from "@iconify/react";
import { BiLogOut } from "react-icons/bi";
import { WiDayCloudy } from "react-icons/wi";

function Sidebar() {
  const { reset,setIsExpanded,isSidebarOpen,setSidebarOpen,logout,setRootFoldersNavigation } = useContext(UserContext);
  const [isMobileView, setIsMobileView] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1024); // Detect mobile view
    };
    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(()=>{
    if(isMobileView){
      {setSidebarOpen(true)}
    }
    else{
      setSidebarOpen(false)
    }
  },[isMobileView])


  return (
    <>
      <div className={`left-sidebar-wrapper ${(isSidebarOpen && setIsExpanded) ? "show-sidebar conditioncollpase " : ""}`}>
        <nav className={`sidebar` } 
           style={{ display:  isMobileView && isSidebarOpen ? "none" : "" }}   // do it only in mobile view
          >
          {/* Sidebar Header */}
      <div className={`sidebar-header text-left`}>
          <NavLink activeclassname="isActive" to="/">
              ADMIN
            </NavLink>
          </div>

          {/* Navigation Menu */}
          <div  className="left-nav-menu" style={{ minWidth: isSidebarOpen && setIsExpanded ? "80px" : "260px" }}>
            <DropDownMenu >
              <Menu path="/dashboard"  title="Dashboard" icon="ic:baseline-home"   iconifyIcon="true"  isExpanded = {false} ></Menu>
              <Dropdown title="Folders" icon="tabler:user" iconifyIcon="true">
                <Menu path="/folder/null/personalfolder/id" title="Personal Folder"  icon="ic:baseline-folder"   iconifyIcon="true"    isExpanded = {false}  onClick={()=>{setRootFoldersNavigation(true)}}></Menu>
                <Menu path="/sharedfolder/null/sharedFolder/id" title="Shared Folder"   icon="mdi:file-document" iconifyIcon="true"   isExpanded = {false}></Menu>
                <Menu path="/favorites" title="Favorites"   icon="mdi:star" iconifyIcon="true"   isExpanded = {false}></Menu>
              </Dropdown>
            </DropDownMenu>

            {/* Logout Option */}
            {/* <ul>
              <li>
                <a title="Logout" onClick={() => logout()} className="logout">
                  <span className="icon-log-out"></span>
                  <span className="menu_txt">Logout</span>
                </a>
              </li>
            </ul> */}
          </div>

          {/* Version Info */}
          {/* <div className="version">
            <span className="vs-mobile">Version 1.0</span>
          </div> */}
        </nav>
      </div>
    </>
  );
}

export default Sidebar;
