import React, { createContext, useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/authServices";
export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const navigate = useNavigate();
    const [auth, setAuth] = useState(null);
    const [Theme, setTheme] = useState("theme-5");
    const [ThemeColor, setThemeColor] = useState("theme-color-14");
    const [isExpanded,setIsExpanded] =useState(false)
    const [MiniSidebar, setMiniSidebar] = useState(false); 
    const [userInfo, setUserInfo] = useState({ name: "", email: "" });
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [counts, setCounts] = useState({});
    const [notificationData, setNotificationData] = useState([]);
      const [isSidebarOpen, setSidebarOpen] = useState(false);
      const[rootFoldersNavigation,setRootFoldersNavigation] =useState(false)
    const login = async (email, password) => {
        const data = await AuthService.login(email, password);
        setAuth(data);
        return data;
    };

    const isLoggedIn = () => {
        return !!localStorage.getItem('user');
    };
   
    const logout = async() => {
        await AuthService.logout();
        setAuth(null);
        reset()
    };

    // refresh Token API 
     useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem("user"));
        const refreshTokenInterval = setInterval(async () => {
            const storedUser = JSON.parse(localStorage.getItem("user"));
            if (storedUser && storedUser.data.refreshToken) {
                const newAccessToken = await AuthService.refreshAccessToken(
                    storedUser.data.accesstoken, 
                    storedUser.data.refreshToken
                );
                if (!newAccessToken) {
                    console.error("Refresh Token not found");
                    setAuth(null); 
                    reset()
                } 
            } else {
                console.error("No refresh token found, skipping refresh.");
            }
        }, 15 * 60 * 1000); // Refresh every 15 minutes for security purposes
        
        return () => clearInterval(refreshTokenInterval);
    }, [auth]); 

    useEffect(() => {
        if (!isLoggedIn()) {
              setAuth(null)
          }
          else{setAuth(true);}
      }, [isLoggedIn, navigate]);


    const reset = () => {
        setUserInfo({ name: "", email: "" });
        setCounts({});
        setBreadcrumbs([]);
        setNotificationData([]);
        localStorage.clear();
        // navigate("/"); 
    };

    return (
        <UserContext.Provider
            value={{
                ThemeColor,Theme,
                auth,MiniSidebar,
                reset,counts,
                setCounts,setThemeColor,
                setTheme,setAuth,
                setMiniSidebar,userInfo,
                setUserInfo, breadcrumbs,
                setBreadcrumbs,notificationData,
                setNotificationData,
                isExpanded,setIsExpanded,
                login,logout,isLoggedIn,
                isSidebarOpen, setSidebarOpen,
                rootFoldersNavigation,setRootFoldersNavigation,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};
