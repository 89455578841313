import React, { useContext, useState } from 'react';
import { Link, NavLink,useNavigate } from 'react-router-dom';
import { UserContext } from "../../context/theme";
import { Icon } from "@iconify/react";
import logo from "../../assets/images/droploom-logo-2.svg"

const Notification = [
    {
        Avatar: '/assets/images/avatar-3.jpg',
        Name: "John Doe",
        Description: "Lorem ipsum dolor sit amet, consectetur elit.",
        NotificDate: "02 Jan 2022"
    },
    {
        Avatar: '/assets/images/avatar-3.jpg',
        Name: "Tailor",
        Description: "Lorem ipsum dolor sit amet, consectetur elit.",
        NotificDate: "03 Jan 2022"
    },
    {
        Avatar: '/assets/images/avatar-3.jpg',
        Name: "Cherrry Warner",
        Description: "Lorem ipsum dolor sit amet, consectetur elit.",
        NotificDate: "04 Jan 2022"
    }
];

const Setting = [
    {
        title: "Profile",
        icon: "icon-user",
        to: "/profile"
    },
    {
        title: "Logout",
        icon: "icon-log-out",
        to: "/"
    },
];

function TopNavbar() {
    const { userInfo, setAuth, logout, reset,setIsExpanded,isSidebarOpen, setSidebarOpen } = useContext(UserContext);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [isNotificationOpen, setNotificationOpen] = useState(false);
    const [isProfileDropdownOpen, setProfileDropdownOpen] = useState(false);
    const navigate=useNavigate();
    
      const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
        setIsExpanded(!isSidebarOpen)
      };

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const toggleProfileDropdown = () => {
        setProfileDropdownOpen(!isProfileDropdownOpen);
    };

    const toggleNotificationDropdown = () => {
        setNotificationOpen(!isNotificationOpen);
    };

    const Logout = () => {
        logout();
        localStorage.removeItem("user");
        setAuth(false);
        navigate('/')
    };

    return (
        <div className="navbar_notification">
            {/* Left side - Logo and Admin */}
            <button className="sidebar-toggle-btn" onClick={toggleSidebar}>
                        <Icon icon="mdi:menu" fontSize={24} />
                      </button>
                      
            <div className="navbar-left">
                {/* <NavLink activeclassname="isActive" to="/" className="navbar-admin-text">
                    ADMIN
                </NavLink> */}
                    <img src={logo} alt="Profile" className='logo'/>
                    
                
            </div>

            {/* Right side - Notification Icon, Profile Avatar and Dropdown */}
            <ul className="navbar-right">
                {/* Notification Icon */}
                <li>
                    <button className="notification-btn" onClick={toggleNotificationDropdown}>
                        <Icon icon="mdi:bell-outline" fontSize={24} />
                    </button>

                    {/* Notification Dropdown */}
                    <ul className={`dropdown-menu notification-menu ${isNotificationOpen ? "show" : ""}`} aria-labelledby="notificationDropdown">
                        {Notification.map((item, i) => (
                            <li key={i}>
                                <div className="notification-item">
                                    <img src={item.Avatar} alt={item.Name} className="notification-avatar" />
                                    <div>
                                        <p className="notification-name">{item.Name}</p>
                                        <p className="notification-description">{item.Description}</p>
                                        <span className="notification-date">{item.NotificDate}</span>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </li>

                {/* Profile Avatar and Dropdown */}
                <li>
                    <button className="profile-btn" onClick={toggleProfileDropdown}>
                        <img src="https://randomuser.me/api/portraits/men/1.jpg" alt="Profile" className="profile-avatar" />
                    </button>

                    {/* Profile Dropdown */}
                    <ul className={`dropdown-menu profile-dropdown ${isProfileDropdownOpen ? "show" : ""}`} aria-labelledby="profileDropdown">
                        {Setting.map((item, i) => (
                            <li key={i}>
                                {item.title === "Logout" ? (
                                    <a onClick={() => { Logout(); toggleProfileDropdown(); }}>
                                        <i className={item.icon}></i> {item.title}
                                    </a>
                                ) : (
                                    <NavLink to={item.to} onClick={toggleProfileDropdown}>
                                        <i className={item.icon}></i> {item.title}
                                    </NavLink>
                                )}
                            </li>
                        ))}
                    </ul>
                </li>
            </ul>
        </div>
    );
}

export default TopNavbar;
