import React, { useContext, useState } from 'react';
import logo from "../../assets/images/droploom-logo-2.svg"

function TopNavbar1() {
    return (
        <div className="navbar_notification" style={{padding:"20px 20px"}}>
            <div className="navbar-left">
                    <img src={logo} alt="Profile" className='logo'/>
            </div>
        </div>
    );
}

export default TopNavbar1;
