import { UserContext, UserProvider } from "./context/theme";
import Layout from "./layout/layout";
import UnAuth from "./unauth/unauth";
import './assets/sass/main.scss'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import '@mdi/font/css/materialdesignicons.min.css';


function App() {
  let token =localStorage.getItem("publicLinkToken")
  return (
    <div className="App">
           <UserProvider>
           <UserContext.Consumer>
             {({ auth }) => {
               return (auth || token)  ? <Layout />:<UnAuth /> ;
              }}
            </UserContext.Consumer>
        </UserProvider>
    </div>
  );
}

export default App;
