import { useContext, useEffect, useRef, useState } from "react";
import { Link, NavLink, useLocation,useParams } from "react-router-dom"
import { UserContext } from "../../context/theme";
import 'iconify-icon';



const DropDownMenu = (props) =>{
    const {children} = props ;
    const {setIsExpanded, isExpanded,isSidebarOpen, setSidebarOpen } = useContext(UserContext); 
    // console.log(children)
    return(
        <>
            <div className="sidebarMenu">
                <ul className="metismenu"    onClick={() => {
                        setSidebarOpen(false);
                        setIsExpanded(false)
                      }}>
                    {children}
                </ul>
            </div>
        </>
    )
}

const Dropdown = (props) =>{
    const { pathname } = useLocation();
    const {children, title, icon,iconifyIcon , img} = props ;
    const {setIsExpanded, isExpanded,isSidebarOpen, setSidebarOpen } = useContext(UserContext); 
    let matchPath = pathname || '';
    let iconMenu = icon || 'icon-user';
    let iconfy = iconifyIcon || '';
    let target = title.replace(/ /g, "_") || ''
    let arrCheck = Array.isArray(children);
    const pathFilter = arrCheck &&  children.filter((d)=> d.props.path == matchPath );
    const [isOpen, setIsOpen] = useState(false);
    const isActive = pathFilter.length > 0;
    const handleToggle = () => setIsOpen(!isOpen);
    
    return(
        <>
            <li >
              
                  <Link
                    className={`btn-toggle align-items-center ${
                        (isOpen && isActive ) ? "" : "collapsed"
                    }`}
                    to="#"
                    onClick={() => {
                        handleToggle();
                        setSidebarOpen(false);
                        setIsExpanded(false)
                      }}
                    aria-expanded={isOpen || isActive}
                    >
                 
                    {iconfy &&  <span><iconify-icon icon={iconMenu} /></span>}
                    {(!iconfy && iconMenu) &&   <span className={`${iconMenu}`}></span>}
                    {img &&  <span><img src={img}/></span>}
                  <span className="menu_txt">{title}</span>
                  <span className="dropdown-toggle-icon">
                    <iconify-icon icon={isOpen ? 'mdi:chevron-up' : 'mdi:chevron-down'} />
                    </span>
                </Link>
                <ul
                className={`collapse has_menu ${(isOpen) ? "show" : "none" }`}  id={target} onClick={()=>{ setSidebarOpen(false);  setIsExpanded(false)}} >
                {children}
            </ul>
            </li>
            
        </>
    )
}

const Menu = (props) =>{
    const { pathname } = useLocation();
    const {children, path, icon,title, iconifyIcon,isExpanded} = props ;
    let matchPath = path || '/404';
    let iconMenu = icon || '';
    let iconfy = iconifyIcon || '';
    const params = useParams();

    
    const {setBreadcrumbs,setIsExpanded,setSidebarOpen } = useContext(UserContext); 
    useEffect(()=>{
        setIsExpanded(isExpanded)
        let pageInfo = {
            link:path,
            title:title
        }
        if(pathname == matchPath){
            setBreadcrumbs(pageInfo)
        }

    },[pathname]);
    return(
        <>
            <li>
                <Link
                to={matchPath} 
                className={`collapse has_menu ${pathname ==  matchPath? 'active':''}`}
                style={{display:"flex"}}
                onClick={pathname === matchPath ? () => { setSidebarOpen(false);setIsExpanded(false) } : undefined}
                >
                
                    {iconfy?(
                        <span ><iconify-icon icon={iconMenu} /></span>
                    ):(
                        <span className={`${iconMenu}`}></span>
                    )}
                    <span className="menu_txt">{title}</span>
                 
                </Link>
            </li>
        </>
    )
}

export {Dropdown,Menu}
export default DropDownMenu