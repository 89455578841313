import React, { lazy ,useContext,Suspense} from 'react';
import { Routes, Route,useLocation } from 'react-router-dom';
import {ToastContainer } from 'react-toastify'; 
import TopNavbar from '../components/common/topNavbar';
import Header from '../components/common/header';
import { Oval } from 'react-loader-spinner';
import { UserContext } from "../context/theme";
import "../layout/layout.scss";

const Login = lazy(() => import('../pages/auth/login'));
const Register = lazy(() => import('../pages/auth/Register'));
const Public = lazy(() => import('../pages/publicfolder/public'));
const MainPublic =lazy(()=>import('../pages/folder/publicFolder'));

const UnAuth = () => {
  const context = useContext(UserContext); 
  const location = useLocation(); // Get current route

  // Check if route is "/Public/:token"
  const isPublicRoute = location.pathname.startsWith("/public/");

  return (
      <>
          <ToastContainer />
          <div className={isPublicRoute ? `page-wrapper ${context.Theme} ${context.ThemeColor} ${context.MiniSidebar ? "mini-sidebar" : ""}` : ""}>
              {/* {isPublicRoute && <Header />} */}
              {isPublicRoute && <TopNavbar />}

              <div 
                className={isPublicRoute 
                  ? "all-content-wrapper all-content-wrapper_smallscreen " 
                  : ""
                } 
                style={isPublicRoute ? { marginLeft: "unset" } : {}}
              >

                  <Suspense
                      fallback={
                          <div className="loading-container">
                              <div className="loading-spinner">
                                  <Oval
                                      visible={true}
                                      height="80"
                                      width="80"
                                      color="#6f42c1"
                                      primaryColor="#6f42c1"
                                      secondaryColor="#6f42c1"
                                      backgroundColor="#6f42c1"
                                      ariaLabel="oval-loading"
                                  />
                              </div>
                          </div>
                      }
                  >
                      <Routes>
                          <Route path="/" element={<Login />} />
                          <Route path="/login" element={<Login />} />
                          <Route path="/register" element={<Register />} />
                          <Route path="/Public/:token" element={<MainPublic />} />
                      </Routes>
                  </Suspense>
              </div>
          </div>
      </>
  );
};

export default UnAuth;